import {
    Button,
    Datagrid,
    FunctionField, HttpError,
    List,
    TextField,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRefresh
} from "react-admin";
import {format} from "date-fns";
import CustomOrganizationInput from "../Members/CustomOrganizationInput";
import React from "react";
import {FaRedoAlt} from "react-icons/fa";
import DeleteIcon from "@mui/icons-material/Delete";
import Cancel from "@mui/icons-material/Cancel";

const filters = [
    <CustomOrganizationInput label={"Organization"} source="organizationKey" resourceToFetch="organizations" />,
];

const Resubscribe = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = async () => {
        try {
            await dataProvider.resubscribeMailboxAssignment(record.emailAddress, record.organizationKey);
            notify(`Resubscribe Request Sent`, { type: "success" });
            refresh();
        } catch (error) {
            if (error instanceof HttpError) {
                console.error("Error sending request:", error);
                notify("Error sending request. See console for details", { type: "error" });
            }
        }
    };

    return (
        <Button
            label="Resubscribe"
            onClick={handleClick}
        >
            <FaRedoAlt />
        </Button>
    );
};

const DeleteAssignment = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = async () => {
        try {
            await dataProvider.deleteMailboxAssignment(record.subscriptionId);
            notify(`Delete Request Sent`, { type: "success" });
            refresh();
        } catch (error) {
            if (error instanceof HttpError) {
                console.error("Error sending request:", error);
                notify("Error sending request. See console for details", { type: "error" });
            }
        }
    };

    return (
        <Button
            onClick={handleClick}
        >
            <DeleteIcon />
        </Button>
    );
};

const CancelAssignment = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = async () => {
        try {
            await dataProvider.cancelMailboxAssignment(record.emailAddress, record.organizationKey);
            notify(`Delete Request Sent`, { type: "success" });
            refresh();
        } catch (error) {
            if (error instanceof HttpError) {
                console.error("Error sending request:", error);
                notify("Error sending request. See console for details", { type: "error" });
            }
        }
    };

    return (
        <Button
            label="Cancel"
            onClick={handleClick}
        >
            <Cancel />
        </Button>
    );
};

export const MailboxAssignmentList = () => (
    <List
        filters={filters}
        sort={{ field: "organizationKey", order: "ASC" }}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="subscriptionId" />
            <TextField source="organizationKey" />
            <TextField source="organizationName" />
            <TextField source="emailAddress" />
            <FunctionField
                label="Expiration"
                render={(record: { expiration: any }) =>
                    `${format(
                        new Date(record.expiration),
                        "MMMM do yyyy, h:mm:ss a"
                    )}`
                }
            />
            <FunctionField
                label="Status"
                render={(record: { expiration: any }) =>
                    `${record.expiration && new Date(record.expiration) > new Date() ? 'Active' : 'Inactive'}`
                }
            />
            <Resubscribe />
            <CancelAssignment />
            <DeleteAssignment />
        </Datagrid>
    </List>
);