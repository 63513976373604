import React, { useState, useEffect } from 'react';
import { Button, TextField, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Drawer } from '@mui/material';
import {useDataProvider, useNotify, useRecordContext} from "react-admin";
import {formatDate} from "../../../utils/general";

interface Token {
    username?: string;
    nextScheduledRefreshTime: string;
    isValid: boolean;
    refreshableApiTokenSource: string;
}

type Tokens = {
    [key: string]: Token | null;
};

const RefreshableApiToken: React.FC = (props) => {
    const [tokens, setTokens] = useState<Tokens>({ TradeDesk: null, AXS: null });
    const [loading, setLoading] = useState(true);
    const [editingToken, setEditingToken] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [formValues, setFormValues] = useState({ organizationKey: '', refreshableApiTokenSource: '', username: '', password: '' });
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const record = useRecordContext(props);

    useEffect(() => {
        fetchTokens();
    }, []);

    const fetchTokens = async () => {
        setLoading(true);
        try {
            const response = await dataProvider.getRefreshableApiTokensForOrganization(record.organizationKey);
            const tokensData = response.json.reduce((acc: any, token: any) => {
                acc[token.refreshableApiTokenSource] = token;
                return acc;
            }, { TradeDesk: null, AXS: null });
            setTokens(tokensData);
        } catch (error) {
            console.error(error);
            notify('Error fetching tokens', { type: 'error' });
        }
        setLoading(false);
    };
    

    
    const handleAddOrEditToken = async () => {
        try {
            setLoading(true);
            await dataProvider.saveRefreshableApiToken({data: formValues});
            setDrawerOpen(false);
            notify('Token saved successfully', { type: 'success' });
        } catch (error) {
            console.error(error);
            notify('Error saving token', { type: 'error' });
        } finally {
            fetchTokens();
        }
    };

    const handleForceRefresh = async (source: string) => {
        try {
            setLoading(true);    
            await dataProvider.forceRefreshRefreshableApiToken(record.organizationKey, source);
            notify('Token refreshed successfully', { type: 'success' });
        } catch (error) {
            console.error(error);
            notify('Error refreshing token', { type: 'error' });
        } finally {
            fetchTokens();
        }
    };

    const openDrawer = (token: any) => {
        setEditingToken(token);
        setFormValues({
            organizationKey: record.organizationKey,
            refreshableApiTokenSource: token ? token.refreshableApiTokenSource : '',
            username: token ? token.username : '',
            password: ''
        });
        setDrawerOpen(true);
    };

    return (
        <div>
            <h2>Refreshable API Tokens</h2>
            <Button onClick={fetchTokens} variant="contained" color="primary" style={{ marginBottom: 20 }}>
                Refresh Tokens
            </Button>
            {loading ? (
                <CircularProgress />
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>RefreshableApiTokenSource</TableCell>
                            <TableCell>UserName</TableCell>
                            <TableCell>NextScheduledRefreshTime</TableCell>
                            <TableCell>IsValid</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {['TradeDesk', 'AXS'].map((source: string) => (
                            <TableRow key={source}>
                                <TableCell>{source}</TableCell>
                                <TableCell>{tokens[source]?.username || ''}</TableCell>
                                <TableCell>
                                    {tokens[source]?.nextScheduledRefreshTime
                                        ? formatDate(tokens[source]!.nextScheduledRefreshTime!)
                                        : ''}
                                </TableCell>
                                <TableCell>{tokens[source]?.isValid ? 'Yes' : 'No'}</TableCell>
                                <TableCell>
                                    {tokens[source] ? (
                                        <>
                                            <Button onClick={() => openDrawer(tokens[source])}>Edit</Button>
                                            <Button onClick={() => handleForceRefresh(source)}>Force Refresh</Button>
                                        </>
                                    ) : (
                                        <Button onClick={() => openDrawer({ refreshableApiTokenSource: source })}>Add</Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
            <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <div style={{ padding: 20, width: 300 }}>
                    <h3>{editingToken ? 'Edit Token' : 'Add Token'}</h3>
                    <TextField
                        label="Source"
                        value={formValues.refreshableApiTokenSource}
                        onChange={(e) => setFormValues({ ...formValues, refreshableApiTokenSource: e.target.value })}
                        disabled
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Username"
                        value={formValues.username}
                        onChange={(e) => setFormValues({ ...formValues, username: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Password"
                        type="password"
                        value={formValues.password}
                        onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <Button onClick={handleAddOrEditToken} variant="contained" color="primary" fullWidth>
                        Save
                    </Button>
                </div>
            </Drawer>
        </div>
    );
};

export default RefreshableApiToken;