import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { format, parseISO } from "date-fns";

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export async function extractToken(
  account: AccountInfo,
  instance: IPublicClientApplication
) {
  const accessTokenRequest = {
    scopes: ["openid"],
    account: account,
  };
  let accessTokenResponse = await instance.acquireTokenSilent(
    accessTokenRequest
  );
  let token = accessTokenResponse.idToken;
  return token;
}

export const formatInstantDate = (dateRecord: any) => {
  const dateString = `${dateRecord.year}-${addZero(dateRecord.month)}-${addZero(dateRecord.day)}`;
  const formattedDate = format(parseISO(dateString), "EEEE MMMM d, yyyy");
  return format(parseISO(dateString), formattedDate)
}

export const addZero = (i: number) => {
  let str = i.toString();
  if (i < 10) {
    str = 0 + str;
  }
  return str;
}


export const formatDate = (date: string): string => {
  return format(
      new Date(date),
      "MMMM do yyyy, h:mm:ss a"
  )
};