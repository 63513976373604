import {FaPiggyBank} from "react-icons/fa";
import {PurchaseAccountList} from "../components/Purchase/PurchaseAccountList";

const PurchaseAccounts = {
    name: "purchase-accounts",
    icon: FaPiggyBank,
    list: PurchaseAccountList,
    menuLabel: "Purchase Accounts",
};

export default PurchaseAccounts;