import {PurchaseAccountStatusTypeKeys, PurchaseSource} from '../../utils/constants';

export const purchaseSourceChoices = Object.values(PurchaseSource)
    .map(source => ({
        id: source,
        name: source
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

export const purchaseAccountStatusChoices = Object.values(PurchaseAccountStatusTypeKeys)
    .map(source => ({
        id: source,
        name: source
    }))
    .sort((a, b) => a.name.localeCompare(b.name));