import React, { useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  minLength,
  useDataProvider,
  useNotify,
  HttpError,
} from "react-admin";
import { phoneNumberFormat } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import {
  errorMap,
} from "../../utils/errors";
import { EditToolbar } from "../CustomToolbar/EditToolbar";

export const InvitationEdit = () => {
  const navigate = useNavigate();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [roleChoices, setRoleChoices] = useState([] as any);
      useEffect(() => {
          const fetchRoles = async () => {
              const resp = await dataProvider.getRoles();     
             const choices= resp.json.map( (r: { key: any; name: any; }) =>  {
               return ({ id: r.key, name:r.name });
             });    
              setRoleChoices(choices);            
          }
          fetchRoles();
      },[dataProvider]);

  const handleEdit = async (formData: any) => {
    try {
      await dataProvider.sendInvitation({ data: formData });
      navigate("/invitations");
    } catch (error) {
      if (error instanceof HttpError) {
        const errorCode = error.body[0].errorCode;
      const errorMessage = error.body[0].errorMessage;

      if (errorMessage) notify(errorMessage, { type: "error" });
      else notify(errorMap[errorCode], { type: "error" });
      }
    }
  };

  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm onSubmit={handleEdit} toolbar={<EditToolbar />}>
        <TextInput source="displayName" label="Full Name" />
        <TextInput source="email" disabled />
        <TextInput
          source="phone"
          format={phoneNumberFormat}
          parse={phoneNumberFormat}
          validate={minLength(14)}
        />
        <ReferenceInput
          source="organizationId"
           reference="organization-details"
          filter={{ status_eq: ["Active", "Pending"] }}
          perPage={99999}
          required
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        {roleChoices && 
         <SelectInput source="defaultUserRole" choices = {roleChoices} optionText="name"  required/>
}
        <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
      </SimpleForm>
    </Edit>
  );
};
