import {
    BooleanInput,
    Datagrid,
    List,
    SearchInput,
    SelectArrayInput,
    SelectInput,
    TextField,
    useDataProvider,
    useListContext
} from "react-admin"

import {FC, useCallback, useEffect, useRef, useState} from "react";

import {purchaseAccountStatusChoices, purchaseSourceChoices} from "./common";


export const PurchaseAccountList = () => {
    const dataProvider = useDataProvider();
    const [entries, setEntries] = useState<any[]>([]);

    const getEntries = useCallback(() => {
        dataProvider
            .getOrganizations()
            .then((data: any) => {
                setEntries(data)
            });
    }, [dataProvider]);

    interface PurchaseProfileFilterProps {
        source?: string;
        label?: string
    }
    const PurchaseProfileFilter: FC<PurchaseProfileFilterProps> = ({ source }) => {
        const { filterValues } = useListContext(); // Access the current filter values
        const dataProvider = useDataProvider();
        const [purchaseProfiles, setPurchaseProfiles] = useState<any[]>([]);
        const lastOrgKeyRef = useRef<string | null>(null);

        useEffect(() => {
            const currentOrgKey = filterValues.organizationKey;
            if (!currentOrgKey) {
                setPurchaseProfiles([]);
                lastOrgKeyRef.current = null;
                return;
            }
            // Only fetch if the organization key has changed
            if (lastOrgKeyRef.current === currentOrgKey) {
                return;
            }
            lastOrgKeyRef.current = currentOrgKey;
            dataProvider
                .getList("purchase-profiles", {
                    pagination: { page: 1, perPage: 999999 },
                    sort: { field: "name", order: "ASC" },
                    filter: { organizationKey: currentOrgKey },
                })
                .then((response: any) => {
                    const profiles = response.data.map((profile: any) => ({
                        id: profile.id,
                        name: profile.name,
                    }));
                    setPurchaseProfiles(profiles);
                });
        }, [filterValues.organizationKey, dataProvider]);

        return (
            <SelectInput
                label="Purchase Profile"
                source={source || "profileId"}
                choices={purchaseProfiles}
                optionText="name"
                optionValue="id"
            />
        );
    };

    useEffect(() => {
        getEntries();
    }, [getEntries]);

    

    const filters = [
        <SearchInput
            source="searchText"
            alwaysOn />,

        <SelectInput
            label="Organization"
            alwaysOn
            emptyText={"Select an Organization"}
            key="organizationKey"
            source="organizationKey"
            optionValue="organizationKey"
            hiddenLabel={false}
            choices={entries}
            required
            optionText={(record: { name: any; knownAs: any; id: any }) =>
                `${record.name} [${record.knownAs ?? ''} - ${record.id}]`
            }
        />,
        <SelectArrayInput
            label="Purchase Source"
            source="purchaseSource"
            choices={purchaseSourceChoices}
        />,
        <SelectArrayInput
            label="Status"
            source="accountStatusType"
            choices={purchaseAccountStatusChoices}
        />,
        
        <PurchaseProfileFilter source="profileId" label="Purchase Profile" />,
        <BooleanInput source="hasNoProfile" label="No Profile" />
        
    ];
    return <List filters={filters} filterDefaultValues={{ showDeleted: false }} debounce={500} empty={false} >
        <Datagrid bulkActionButtons={false} >
            <TextField source="purchaseSource" label="Purchase Source" />
            <TextField source="purchaseProfile.name" label="Purchase Profile"/>
            <TextField source="name" label="Name" />
            <TextField source="linkedAccount.name"  label="Linked"/>
            <TextField source="accountStatusDetail"  label="Status"/>
            <TextField source="lastAccountValidateDateTime" label="Validated"/>
            <TextField source="lastAccountRunDateTime" label="Last Time Ran"/>
            <TextField source="nextAccountRunDateTime" label="Next Scheduled Run"/>
        </Datagrid>
    </List>
}
