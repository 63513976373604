import {FaPiggyBank} from "react-icons/fa";
import {PurchaseAccountList} from "../components/Purchase/PurchaseAccountList";

const PurchaseProfiles = {
    name: "purchase-profiles",
    icon: FaPiggyBank,
    list: PurchaseAccountList,
    menuLabel: "Purchase Profiles",
};

export default PurchaseProfiles;